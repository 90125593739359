import React, {useContext, useEffect, useRef, useState} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import {Link} from "gatsby";
import LangContext from "../../context/LangContext";
import asesoriafinanciera from "../../../static/images/lca/asesoriafinanciera.jpg"
import estructuracion from "../../../static/images/lca/estructuracion.jpg"
import valuacion from "../../../static/images/lca/valuacion.jpg"
import reestructura from "../../../static/images/lca/reestructura.jpg"
import gestionfondos from "../../../static/images/lca/gestionfondos.jpg"

const ServiciosPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    const {lang} = useContext(LangContext)
    const [data, setData] = useState({})
    if (lang === "es") {
        import ("../../messages/es")
            .then((msg) => {
                setData(msg.default)
            })
    } else {
        import ("../../messages/en")
            .then((msg) => {
                setData(msg.default)
            })
    }
    return (
        <Layout location={props.location}>
            <SEO title="Nuestros servicios | LCA"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.8"
                         style={{backgroundImage: 'url("/images/lca/header3.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h1 className="alt-font text-white opacity-6 margin-20px-bottom">
                                    Ayudamos a resolver retos financieros importantes</h1>
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    Nuestros servicios</h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-5 col-md-6">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    Amplia gama de soluciones financieras en distintos productos</h5>
                                <p className="w-90 lg-w-90">Combinamos nuestra dedicación a la excelencia
                                    con capacidad técnica y conocimientos de mercado para brindar a nuestros clientes
                                    servicios de asesoría
                                    especializados y adaptados a sus necesidades.</p>
                                <p className="w-90 lg-w-90">Nuestros conocimientos del mercado y nuestra capacidad
                                    técnica nos permiten aplicar diferentes metodologías de valoración de activos y
                                    modelación de flujos de caja.</p>
                            </div>
                            <div className="col-12 col-md-6 offset-lg-1 sm-margin-30px-bottom">
                                <img className="" src={"/images/lca/interior/servicios.jpg"} alt={''}/>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}

                {/* start section */}
                <section className="wow animate__fadeIn">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 sm-margin-30px-bottom">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray mb-0">
                                    Estructuramos financiamientos innovadores y evaluamos las mejores opciones para
                                    nuestros clientes y sus accionistas. Prestamos asesoría a proyectos desde sus etapas
                                    más tempranas.
                                </h5>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 col-md-7">
                                <div className="row row-cols-1 row-cols-sm-2">
                                    {/* start counter item */}
                                    <div
                                        className="col counter-style-01 last-paragraph-no-margin xs-margin-30px-bottom">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                        >Relaciones <br className="d-none d-md-block"/> &nbsp;</h5>

                                        <p className="w-85 md-w-100">Mantenemos estrechas relaciones con una variada
                                            gama de proveedores de capital, deuda y garantías financieras, entre los
                                            cuales se incluyen entidades financieras multilaterales y banca de
                                            desarrollo.
                                        </p>
                                    </div>
                                    {/* end counter item */}
                                    {/* start counter item */}
                                    <div className="col counter-style-01 last-paragraph-no-margin">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                        >Nuestros clientes</h5>

                                        <p className="w-85 md-w-100">Entre nuestros clientes se encuentran promotores y
                                            desarrolladores de proyectos, inversionistas, accionistas, y entidades de
                                            gobierno, a quienes les ofrecemos servicios de consultoría y apoyo para sus
                                            funciones.</p>
                                    </div>
                                    {/* end counter item */}
                                </div>
                            </div>
                            <div className="col-12" id="tab-servicios">
                                <div
                                    className="w-100 h-1px bg-medium-gray margin-7-rem-top margin-8-rem-bottom sm-margin-5-rem-tb"/>
                            </div>
                        </div>
                        <div className="row tab-servicios">
                            <div className="col-12 tab-style-01 wow animate__fadeIn" data-wow-delay="0.4s">
                                {/* start tab navigation */}
                                <ul className="nav nav-tabs text-uppercase justify-content-center text-center alt-font font-weight-500 margin-7-rem-bottom lg-margin-5-rem-bottom md-margin-4-rem-bottom sm-margin-20px-bottom">
                                    <li className="nav-item asesoria"><a className="nav-link asesoria active"
                                                                         data-toggle="tab"
                                                                         href="#asesoria-tab">Asesoría financiera y
                                        Estructuración</a>
                                    </li>
                                    <li className="nav-item valuacion"><a className="valuacion nav-link"
                                                                          data-toggle="tab"
                                                                          href="#valuacion">Valuación y opiniones
                                        independientes</a></li>
                                    <li className="nav-item situaciones"><a className="situaciones nav-link"
                                                                            data-toggle="tab"
                                                                            href="#situaciones">Reestructuras y
                                        situaciones
                                        especiales</a></li>
                                    <li className="nav-item gestion-tab"><a className="gestion-tab nav-link"
                                                                            data-toggle="tab"
                                                                            href="#gestion-tab">Gestión de fondos</a>
                                    </li>
                                </ul>
                                {/* end tab navigation */}
                                <div className="tab-content">
                                    {/* start tab item */}
                                    <div id="asesoria-tab" className="asesoria_content tab-pane fade in active show">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 text-right sm-margin-40px-bottom">
                                                <img src={asesoriafinanciera}
                                                     className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Asesoría financiera y Estructuración</h5>
                                                <p className="w-85 lg-w-100">Latam Capital Advisors se destaca por su
                                                    considerable experiencia desarrollando transacciones financieras
                                                    innovadoras para inversionistas en infraestructura del sector
                                                    público como del privado.
                                                </p>
                                                <p>Ofrecemos asesoría financiera especializada, estructuración y apoyo
                                                    en el proceso de evaluación, decisión, negociación y ejecución de:
                                                </p>
                                                <ul className="list3">
                                                    <li>Financiamientos en el mercado de capitales, banca comercial y de
                                                        desarrollo
                                                    </li>
                                                    <li>Deuda subordinada o mezzanine</li>
                                                    <li>Emisión de instrumentos de capital (capital privado, ofertas
                                                        públicas o instrumentos similares, ej. FIBRAs y FIBRAs E)
                                                    </li>
                                                    <li>Fusiones y Adquisiciones (M&A)</li>
                                                    <li>Garantías financieras</li>
                                                    <li>Reestructuras y refinanciamientos</li>
                                                    <li>Evaluación de viabilidad de nuevos proyectos</li>
                                                    <li>Presentación de licitaciones y propuestas no solicitadas</li>
                                                    <li>Estructura de capital óptima</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="valuacion" className="valuacion_content tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 text-right sm-margin-40px-bottom">
                                                <img src={valuacion} className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Valuación y opiniones independientes</h5>
                                                <p className="w-85 lg-w-100">
                                                    La valuación es un componente esencial en procesos de inversión,
                                                    financiamientos, reestructuras y fusiones y adquisiciones.
                                                </p>
                                                <p>En LCA desarrollamos modelos propios de manera específica para cada
                                                    proyecto o situación a analizar, incorporando los conocimientos más
                                                    avanzados de análisis financiero, estadístico, de probabilidad y
                                                    econométrico.
                                                </p>
                                                <p>Ofrecemos nuestros servicios expertos de valuación de proyectos a
                                                    gobiernos e inversionistas del sector privado para la toma de
                                                    decisiones fundamentadas en información y análisis de alta calidad.
                                                </p>
                                                <p>Hemos participado otorgando fairness opinions necesarias en
                                                    adquisiciones o fusiones.
                                                </p>
                                                <Link to="/es/contacto"
                                                      className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="situaciones" className="situaciones_content tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 text-right sm-margin-40px-bottom">
                                                <img src={reestructura}
                                                     className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Reestructuras y situaciones especiales</h5>
                                                <p className="w-85 lg-w-100">Proponemos a nuestros clientes soluciones
                                                    claras y viables para situaciones complejas, enfocados en los
                                                    intereses de nuestros clientes.
                                                </p>
                                                <p>Nos apoyamos en nuestra extensa experiencia para asesorar en
                                                    reestructuras, renegociaciones, refinanciamientos o solución de
                                                    conflictos.
                                                </p>
                                                <p>También ofrecemos nuestros servicios a participantes del sector
                                                    privado y gobiernos en el diseño de APPs bancables, financiamientos
                                                    innovadores, bursatilizaciones y obtención de garantías de banca de
                                                    desarrollo, agencias multilaterales y entidades de promoción a la
                                                    exportación e inversión, entre otras situaciones.
                                                </p>
                                                <Link to="/es/contacto"
                                                      className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="gestion-tab" className="gestion-tab_content tab-pane fade in">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 text-right sm-margin-40px-bottom">
                                                <img src={gestionfondos}
                                                     className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Gestión de fondos</h5>
                                                <p className="w-85 lg-w-100">Asesoramos en la gestión de fondos de
                                                    inversión en diversos aspectos, incluyendo adquisiciones, monitoreo,
                                                    reestructuras, financiamientos, valuaciones, opiniones
                                                    independientes y desinversiones.
                                                </p>
                                                <p>A partir de 2020, a través de Latam Capital Multifondos, gestionamos
                                                    el fondo de capital privado “Latam Capital Fondo I”, enfocado en
                                                    identificar activos, empresas y proyectos en los que podamos:
                                                </p>

                                                <ul className="list3">
                                                    <li>Ayudar a desarrollar y a acelerar el crecimiento de activos de
                                                        alta
                                                        calidad, en conjunto con promotores y desarrolladores
                                                        excepcionales
                                                        en sectores estratégicos de la región.
                                                    </li>
                                                    <li>Generar valor a través de la optimización de la estrategia
                                                        financiera, la estructura de capital e implementación de mejores
                                                        prácticas administrativas y gobierno corporativo.
                                                    </li>
                                                    <li>Generar retornos atractivos ajustados por riesgo.</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">¿Quieres
                                                    invertir con nosotros o presentar tu proyecto para revisión?
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}
            </>
        </Layout>
    )
}

export default ServiciosPage
